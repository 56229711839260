export const CASES_STATUS ={
    PENDING : "Pending",
    PROCESSING : "Processing",
    COMPLETED: "Completed"
};

export const ASSIGN_TO ={
    DOCTOR : "Doctor",
};

export const PAGINATION ={
    LIMIT_6 : 6,
    LIMIT_10 : 10,
    LIMIT_20 : 20,
    LIMIT_50 : 50,
    LIMIT_100 : 100,
};

export enum CLAIM_STATUS  {
    PAC_INITIATED_PATIENT = "PAC_INITIATED_PATIENT",
    PAC_INITIATED_DOCTOR = "PAC_INITIATED_DOCTOR",
    PAC_SUMITTED = "PAC_SUMITTED",
    ASSESSMENT_IN_PROGRESS = "ASSESSMENT_IN_PROGRESS",
    CLARIFICATION_REQUIRED = "CLARIFICATION_REQUIRED",
    REJECTED = "REJECTED",
    APPROVED = "APPROVED",
    CANCELLED = "CANCELLED",
    PENDING_SUPERVISOR_APPROVAL = "PENDING_SUPERVISOR_APPROVAL",
    PENDING_GE_APPROVAL = "PENDING_GE_APPROVAL",
    PENDING_MASTER_ADVICE = "PENDING_MASTER_ADVICE",
}

export const STP_RESULT = [
    {
        value: "PASS",
        text: "STP Pass",
        background: '#DBF4E9',
        color: '#11854D'
    },
    {
        value: "FAIL",
        text: "Failed",
        color: "#F04646",
        background: "#FFEEEE"
    },
    {
        value: "DIVERT_TO_MANUAL",
        text: "STP Manual Review",
        color: "#03B4CE",
        background: "#EDFBFC"
    },
    {
        value: "PROCESSING",
        text: "STP Processing",
        color: "#77777B",
        background: "#EBEBEB"
    },
];

export const STP_RULE = [
    {
        value: "PASS",
        text: "Pass",
        background: '#EFF8E5',
        color: '#7EC820'
    },
    {
        value: "FAIL",
        text: "Failed",
        color: "#F04646",
        background: "#FFEEEE"
    },
    {
        value: "DIVERT_TO_MANUAL",
        text: "Manual Review",
        color: "#00C1D5",
        background: "#CAF2F6"
    },
];

export const FILTER_CLAIM_STATUS = [
    {
        value: "PAC_INITIATED_PATIENT",
        text: "PAC initiated patient",
    },
    {
        value: "PAC_INITIATED_DOCTOR",
        text: "PAC initiated doctor",
    },
    {
        value: "PAC_SUMITTED",
        text: "PAC Submitted",
    },
    {
        value: "ASSESSMENT_IN_PROGRESS",
        text: "Assessment in Progress",
    },
    {
        value: "CLARIFICATION_REQUIRED",
        text: "Clarification Required",
    },
    {
        value: "PENDING_GE_APPROVAL",
        text: "Pending master approval",
    },
    {
        value: "PENDING_MASTER_ADVICE",
        text: "Pending master advice",
    },
    {
        value: "PENDING_SUPERVISOR_APPROVAL",
        text: "Pending supervisor approval",
    },
    {
        value: "APPROVED",
        text: "Approved",
    },
    {
        value: "REJECTED",
        text: "Rejected",
    },
    {
        value: "CANCELLED",
        text: "Cancelled",
    },
];

export const FILTER_CLAIM_STATUS_ARCHIVE = [
    {
        value: "APPROVED",
        text: "Approved",
    },
    {
        value: "REJECTED",
        text: "Rejected",
    },
    {
        value: "CANCELLED",
        text: "Cancelled",
    },
];


export const CLAIM_STATUS_VALUE_MAP_TEXT:{
    [key: string]: string;
  } = {
    PAC_INITIATED_PATIENT: "PAC initiated patient",
    PAC_INITIATED_DOCTOR: "PAC initiated doctor",
    PAC_SUMITTED: "PAC Submitted",
    ASSESSMENT_IN_PROGRESS: "Assessment in Progress",
    CLARIFICATION_REQUIRED: "Clarification Required",
    PENDING_MASTER_APPROVAL: "Pending master approval",
    PENDING_SUPERVISOR_APPROVAL: "Pending supervisor approval",
    PENDING_GE_APPROVAL: "Pending master approval",
    PENDING_MASTER_ADVICE: "Pending master advice",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    CANCELLED: "Cancelled"
  };

export const CLAIM_STATUS_COLOR: {
    [key: string]: string;
} = {
    PAC_INITIATED_PATIENT: "green",
    PAC_INITIATED_DOCTOR: "orange",
    PAC_SUMITTED: "blue",
    ASSESSMENT_IN_PROGRESS: "yellow",
    CLARIFICATION_REQUIRED: "purple",
    PENDING_SUPERVISOR_APPROVAL: "mintt",
    PENDING_GE_APPROVAL: "pink",
    PENDING_MASTER_ADVICE: "dark-pink",
    APPROVED: "tur",
    REJECTED: "red",
    CANCELLED: "red",
};

export const COMMENT_MODE = {
    INTERNAL: "internal",
    DOCTOR: "doctor",
  };

export const MAP_COLOR_ROLE_COMMENT: {
[key: string]: string;
} = {
    master: "#62AC03",
    supervisor: "#F0AD02",
    assessor: "#01A2FF",
    doctor: "#FE6B00",
    patient: "#6A57FC",
    supperadmin: "#F04646",
};
  
export const FILTER_CASE_STATUS = {
    ARCHIVE: "archive",
    AVAILABLE: "available",
}

export const CASE_TAG = {
    APPROVE_PANEL_RATE: "approve_panel_rate",
    NON_COMPLIANCE_FEES: "non_compliance_fees",
    REFER_NON_PANEL: "refer_non_panel",
    TOSP_CODING: "tosp_coding",
    CASE_ESC_DA: "case_esc_da",
    CASE_ESC_GE: "case_esc_ge",
};

export const MAX_SIZE_UPLOAD = 4.5;

export const INJURY_QUESTIONAIRE_RELEASE_DATE = "2024-10-03T00:00:00.000Z";